// Imports
import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/i18n'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: `/${i18n.locale}`,
    }, {
      path: '/:lang',
      component: () => import('@/layouts/home/Index.vue'),
      render: h => h('router-view'),
      children: [
        {
          path: '/',
          name: 'Home',
          component: () => import('@/views/home/Index.vue'),
        },
        {
          path: 'about',
          name: 'About',
          component: () => import('@/views/about/Index.vue'),
          meta: {
            src: require('@/assets/about_home.jpg'),
            lazySrc: require('@/assets/small/about_home.jpg'),
           },
        },
        {
          path: 'geodesy',
          name: 'Geodesy',
          component: () => import('@/views/job-fields/Geodesy.vue'),
          meta: {
            src: require('@/assets/geodesy_home.jpg'),
            lazySrc: require('@/assets/small/geodesy_home.jpg'),
            option: 'Geodesy',
          },
        },
        {
          path: 'architecture',
          name: 'Architecture',
          component: () => import('@/views/job-fields/Architecture.vue'),
          meta: {
            src: require('@/assets/architecture_home.jpg'),
            lazySrc: require('@/assets/small/architecture_home.jpg'),
            option: 'Architecture',
          },
        },
        {
          path: 'consulting',
          name: 'Consulting & Real Estate',
          component: () => import('@/views/job-fields/Consulting.vue'),
          meta: {
            src: require('@/assets/consulting_home.jpg'),
            lazySrc: require('@/assets/small/consulting_home.jpg'),
            option: 'Consulting & Real Estate',
          },
        },
        {
          path: 'contact-us',
          name: 'Contact',
          component: () => import('@/views/contact-us/Index.vue'),
          meta: {
            src: require('@/assets/contactus_home.jpg'),
            lazySrc: require('@/assets/small/contactus_home.jpg'),
          },
        },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },

  ],
})

export default router
