<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        :title="$t('BusinessInfo.mainTitle')"
        pace="6"
      >
        <v-container class="fill-height">
          <v-responsive
            class="d-flex align-center"
            height="100%"
            width="90%"
          >
            <v-col>
              <v-row>
                <v-avatar
                  
                  size="155"
                  tile
                >
                  <v-img
                  class="rounded-img"
                    :src="require('@/assets/damir.jpg')"
                  />
                </v-avatar>
              </v-row>
              <v-row>
                <v-card-title class="headline">
                  {{ $t('BusinessInfo.title') }}
                </v-card-title>
                <v-card-subtitle>
                  <h3 class="light-blue--text">
                    {{ $t('BusinessInfo.subTitle') }}
                  </h3>
                  {{ $t('BusinessInfo.text') }}
                </v-card-subtitle>
              </v-row>
            </v-col>
          </v-responsive>
        </v-container>
      </base-info-card>

      <base-business-contact dense />
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessInfo',
    dark: false,
    props: { dark: Boolean },
  }
</script>

<style>
  .rounded-img {
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}
</style>
